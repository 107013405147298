<template>
  <div class="page">
    <div class="page-title">
      {{typeName}}
    </div>
    <div class="container">
      <div class="title">请在以下空格中，勾选您感兴趣的区域:</div>
      <div class="list1">
        <van-checkbox-group v-model="result" checked-color="#048DAD">
          <template v-for="(item, idx) in brands">
            <van-checkbox :key="idx" :name="item.booth" >{{item.name}}</van-checkbox>
          </template>
        </van-checkbox-group>
      </div>
      
    </div>
    <van-button type="info" size="normal" color="#048DAD" class="btn" @click="submit()">提 交</van-button>
  </div>
</template>

<script>
  import { submitData } from '@/api/workplace.js';
  import { brands } from './brand.js';
  export default {
    data() {
      return {
        type: -1,
        typeName: '',
        result: [],
        brands: brands,
      }
    },
    created() {
      this.type = this.$route.query.type;
      this.typeName = ['优选品牌', '亮点主题馆'][this.type];
      document.title = this.typeName + ' - 国际公建设计艺术展';
    },
    methods: {
      submit() {
        submitData().then(res => {
          console.log(res);
        })
      },
    },
  }
</script>

<style scoped lang="less">
  @color: #048DAD;
  .page {
    background-color: #EDEDED;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
  }
  .page-title {
    color: #666;
    text-align: center;
    font-size: .28rem;
    margin: 3vh auto;
  }
  
  .container {
    width: 80%;
    margin: 0 auto 3vh;
    
    .title {
      color: #666;
      font-size: .15rem;
      margin-bottom: .12rem;
    }
    
    .list1 {
      
      /deep/ .van-checkbox {
        margin-bottom: .12rem;
        
        .van-checkbox__icon {
          font-size: .18rem;
        }
        .van-checkbox__label {
          font-size: .15rem;
          letter-spacing: 1Px;
        }
      }
    }
  }
  .btn {
    font-size: .16rem;
    height: .4rem;
    border-radius: .05rem;
    width: 60%;
    height: .72rem;
    margin: 0 auto 5vh;
    display: block;
  }


</style>

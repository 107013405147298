export const brands = [
  {
    booth: '5A08',
    name: 'Musepod',
  },
  {
    booth: '5A09',
    name: '米朗',
  },
  {
    booth: '5A11',
    name: '诺梵',
  },
  {
    booth: '5A12',
    name: '凯迪',
  },
  {
    booth: '5A13',
    name: '雅音',
  },
  {
    booth: '5A15',
    name: 'LINAK力纳克',
  },
  {
    booth: '5B12',
    name: 'Haworth海沃氏',
  },
  {
    booth: '5B13',
    name: 'Royal Ahrend安帝尔',
  },
  {
    booth: '5B15',
    name: 'VITRA/USM',
  },
  {
    booth: '5B19',
    name: '兆生',
  },
  {
    booth: '5B21',
    name: '厚朴/ACTIU/PEDRALI',
  },
  {
    booth: '5B25',
    name: '阿基米德',
  },
  {
    booth: '5C19',
    name: '玛拉蒂',
  },
  {
    booth: '5C20',
    name: 'kokuyo国誉',
  },
  {
    booth: '5C21',
    name: 'LYKO国键',
  },
  {
    booth: '5C22',
    name: '奥美丽',
  },
  {
    booth: '5C25',
    name: 'Okamura奥卡姆拉',
  },
  {
    booth: '5C26',
    name: 'Lamex美时家具',
  },
  {
    booth: '5C27',
    name: '优鼎',
  },
  {
    booth: '5D13',
    name: '美稷',
  },
  {
    booth: '5D16',
    name: '欧莱',
  },
  {
    booth: '5D19',
    name: '优合',
  },
];
